import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ReviewCards from "../components/Reviews/ReviewCards";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title=""
        description=""
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-200 pt-16 pb-20 md:pt-20 md:pb-32">
        <div className="container">
          <header className="mx-auto mb-14 text-center md:mb-20 md:max-w-[640px]">
            <h1>Why Real Estate Agents Love Assurance</h1>
            <p>
              Hear from real estate agents like you who discovered Assurance and
              completely transformed their way of doing business.
            </p>
            <ButtonSolid href="/review-us/" text="Leave a Review" />
          </header>

          <div className="mb-16 md:mb-22 md:columns-2 md:gap-x-12">
            <ReviewCards />
          </div>
        </div>

        <CallToAction />
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
